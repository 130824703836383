require('./bootstrap');

window.Vue = require('vue');
window.bus = new Vue();

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.component('userTable',() => import('./components/user/userTable'));

let app = new Vue({
    el: '#app'
});
